import api from '@/libs/api'
import apiRoutes from '@/router/apiRoutes'

export const getAllUserFavoriteProducts = params => new Promise((resolve, reject) => {
  api
    .get(apiRoutes.userFavoriteProducts, { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const createFavoriteIcon = productId => new Promise((resolve, reject) => {
  api
    .post(apiRoutes.userFavoriteProducts, { productId })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const removeFavoriteIcon = productId => new Promise((resolve, reject) => {
  api
    .delete(`${apiRoutes.userFavoriteProducts}/product/${productId}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const removeAll = () => new Promise((resolve, reject) => {
  api
    .delete(`${apiRoutes.userFavoriteProducts}/all`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
