<template>
  <button
    type="button"
    :disabled="getDisabled"
    :style="getBtnStyles"
    :class="getClass"
    :title="buttonTitle"
    @click="action"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonIcon',

  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#f19596',
    },
    marginRight: {
      type: Number,
      default: 0,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      btnClass: 'btn btn-custom-clear',
    }
  },

  computed: {
    getBtnStyles() {
      return {
        color: this.color,
        backgroundColor: 'transparent',
        padding: 0,
        marginRight: `${this.marginRight}px`,
        marginLeft: `${this.marginLeft}px`,
        border: 'none',
      }
    },

    getClass() {
      return this.buttonClass
    },

    getDisabled() {
      return this.disabledButton
    },
  },

  methods: {
    action() {
      this.$emit('action')
    },
  },
}
</script>
