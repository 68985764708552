<template>
  <section class="product-details">
    <b-container v-if="loading">
      <b-row>
        <div
          class="spinner-area"
        >
          <b-spinner />
        </div>
      </b-row>
    </b-container>

    <b-container v-if="!loading">
      <b-row class="mb-5">
        <b-col
          lg="6"
        >
          <div class="product-img">
            <img
              class="img-fluid"
              :src="setProductImageUrl(product.image)"
              alt=""
            >
          </div>
        </b-col>

        <b-col
          lg="6"
        >
          <div class="product-details-text">
            <h4>
              {{ product.productDescription }}
            </h4>

            <div class="text-group">
              <p>
                {{ product.productDetails }}
              </p>
            </div>

            <div class="d-flex">
              <button-form
                button-class="me-3"
                :height="54"
                :border-radius="3"
                @action="handleRedirectWhatsApp"
              >
                <span class="d-flex align-items-center">
                  <b-icon-whatsapp class="me-1" />
                  Solicitar Orçamento
                </span>
              </button-form>

              <button-icon
                v-if="isLogged"
                :disabled="disabledFavoriteIcon"
                title="Adicionar aos favoritos"
                @action="handleAddOrRemoveFavoriteIcon"
              >
                <b-icon-heart-fill
                  v-if="activeFavoriteIcon"
                  class="favorite-icon"
                />

                <b-icon-heart
                  v-if="!activeFavoriteIcon"
                  class="favorite-icon"
                />
              </button-icon>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h2 class="h2-titles">
            Produtos similares
          </h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div
            v-if="loading"
            class="spinner-area"
          >
            <b-spinner />
          </div>

          <div class="carousel-products">
            <card-carousel-slide
              :carousel-data="similarProducts"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
  BIconWhatsapp,
  BIconHeartFill,
  BIconHeart,
} from 'bootstrap-vue'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import ButtonIcon from '@/components/Buttons/ButtonIcon.vue'
import { getAllProducts, showProductByUniqueName } from '@/utils/requests/products'
import { homeRouter } from '@/router/routePathName'
import {
  warningMessageAction,
  warningMessageToast,
} from '@/libs/sweetalerts'
import { messages } from '@/utils/validations/messages'
import { getLinkWhatsAppWithMessage } from '@/utils/whatsApp'
import CardCarouselSlide from '@/components/CarouselProducts/CardCarouselSlide.vue'
import { getArrayAttr } from '@/utils/helpers/helpers'
import { createFavoriteIcon, removeFavoriteIcon } from '@/utils/requests/userFavoriteProducts'
import {filterProductsList, findByUniqueName} from "@/utils/data/products";
import {setProductImageUrl} from "@/utils/helpers/helpers";

export default {
  name: 'ProductDetails',

  components: {
    BContainer,
    BRow,
    BCol,
    BSpinner,
    BIconWhatsapp,
    BIconHeartFill,
    BIconHeart,
    ButtonForm,
    ButtonIcon,
    CardCarouselSlide,
  },

  props: {
    productUniqueName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      setProductImageUrl,
      homeRouter,
      messages,

      loading: false,

      isLogged: this.$store.getters['sessions/isLoggedIn'],

      product: {
        id: '',
        productDescription: '',
        productDetails: '',
        categories: [],
        image: '',
        user: null,
      },

      activeFavoriteIcon: false,
      disabledFavoriteIcon: false,

      similarProducts: [],
    }
  },

  computed: {
    getProductUniqueName() {
      return this.productUniqueName
    },
  },

  watch: {
    getProductUniqueName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleGetData()
      }
    },
  },

  created() {
    if (!this.getProductUniqueName) {
      this.$router.push({ name: homeRouter.name })

      return false
    }

    return this.handleGetData()
  },

  methods: {
    handleGetData() {
      this.handleShowProduct()
      this.handleGetSimilarProducts()
    },

    handleShowProduct() {
        const product = findByUniqueName(
            this.getProductUniqueName
        )

      this.product.id = product.id
      this.product.productDescription = product.product_description
      this.product.productDetails = product.product_details
      this.product.categories = product.category
      this.product.image = product.image ? product.image[0].path : ''
    },

    async handleGetSimilarProducts() {
      if (this.product.categories.length > 0) {
        const categoryId = this.product.categories[0].id

        this.similarProducts = filterProductsList('category', categoryId)
      }
    },

    handleAddOrRemoveFavoriteIcon() {
      this.activeFavoriteIcon = !this.activeFavoriteIcon

      if (this.activeFavoriteIcon) {
        return this.handleAddFavoriteIcon()
      }
      return this.handleRemoveFavoriteIcon()
    },

    async handleAddFavoriteIcon() {
      this.disabledFavoriteIcon = true

      await createFavoriteIcon(this.product.id)
        .then()
        .catch(() => {
          warningMessageToast(messages.impossible)
          this.activeFavoriteIcon = !this.activeFavoriteIcon
        })

      this.disabledFavoriteIcon = false
    },

    async handleRemoveFavoriteIcon() {
      this.disabledFavoriteIcon = true

      await removeFavoriteIcon(this.product.id)
        .then()
        .catch(() => {
          warningMessageToast(messages.impossible)
          this.activeFavoriteIcon = !this.activeFavoriteIcon
        })

      this.disabledFavoriteIcon = false
    },

    handleRedirectWhatsApp() {
      getLinkWhatsAppWithMessage(
        this.$store.state.system.systemData.cellPhone,
        this.productUniqueName,
      )
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
