import {appURL} from "@/libs/api";

export const generateLinkWhatsApp = cellPhone => `https://wa.me/55${cellPhone}`

export const getLinkWhatsAppWithMessage = (cellPhone, productUniqueName) => {
  window.open(
    `https://wa.me/55${cellPhone}?text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento%20para%20este%20produto:%20${appURL}/produtos/detalhes/${productUniqueName}`,
    '_blank',
  )
}
